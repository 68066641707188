import React from 'react';
import Speedometer from './Speedometer';
import "../styles/Speedometer.css"


const MetricCard = ({ title, value }) => {

  //determine the max value based on the title for each metric
  const getMaxForMetric = (title) => {
    switch (title) {
      case "Noise (db)":
        return 140;
      case "Temperature (degC)":
        return 100;
      case "Axial RMS velocity (mm/sec)":
      case "Horizontal RMS velocity (mm/sec)":
      case "Vertical RMS velocity (mm/sec)":
      case "Axial RMS Acceleration (m/s2)":
      case "Horizontal RMS Acceleration (m/s2)":
      case "Vertical RMS Acceleration (m/s2)":
        return 20;
      default:
        return 100; // default max if not specified
    }
  }; 

  const getStepForMetric = (title) => {
    if (title === "Noise (db)") {
      return 14; // Step for Noise metric
    } else if (
      title === "Axial RMS Acceleration (m/s2)" ||
      title === "Horizontal RMS Acceleration (m/s2)" ||
      title === "Vertical RMS Acceleration (m/s2)"||
      title === "Axial RMS velocity (mm/sec)" ||
      title === "Horizontal RMS velocity (mm/sec)" ||
      title ==="Vertical RMS velocity (mm/sec)"
    ) {
      return 2; // Step for Acceleration metrics (2 for 0, 2, 4, 6, ..., 20)
    }
    return 10; // Default step for other metrics
  };

  const max = getMaxForMetric(title); 
  const step = getStepForMetric(title); 
  return(
  <div className="bg-white rounded-lg p-4 shadow line-metric">
    
    <h3 className="text-sm font-medium mb-2 text-center mb-4 text-base">{title}</h3>
        <Speedometer value={value} max={max} step={step}/>
  </div>
  )
  
};

export default MetricCard;