import React, { useState } from "react";

const AddItemForm = ({ type, parentItem, onClose, onAdd }) => {
  const [name, setName] = useState("");
  const [blockName, setBlockName] = useState("");
  const [description, setDescription] = useState("");
  const [truncatedMessage, setTruncatedMessage] = useState("");
  const [deviceData, setDeviceData] = useState({
    machine_id: parentItem?.id || "",
    device_external_id: "",
    type: "",
    status: "active",
    is_active: true,
  });

  const handleInputChange = (field, value) => {
    // Handle character limits based on field type
    let maxLength = 255; // Default max length
    if (field === 'block_name') {
      maxLength = 100;
    } else if (field === 'device_external_id') {
      maxLength = 100;
    } else if (field === 'type') {
      maxLength = 50;
    }

    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
      setTruncatedMessage(`${field} should not exceed ${maxLength} characters.`);
    } else {
      setTruncatedMessage("");
    }

    // Update the appropriate state based on field
    switch (field) {
      case "name":
        setName(value);
        break;
      case "block_name":
        setBlockName(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "device_external_id":
      case "type":
      case "status":
        setDeviceData(prev => ({
          ...prev,
          [field]: value
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (type === "location") {
      if (!blockName.trim()) {
        setTruncatedMessage("Block name is required");
        return;
      }
      if (!name.trim()) {
        setTruncatedMessage("Name is required");
        return;
      }
      const data = {
        block_name: blockName.trim(),
        name: name.trim(),
        description: description.trim()
      };
      onAdd(type, parentItem, data);
    } 
    else if (type === "machine") {
      if (!name.trim()) {
        setTruncatedMessage("Name is required");
        return;
      }
      if (!parentItem?.id) {
        setTruncatedMessage("Location ID is required");
        return;
      }
      const data = {
        name: name.trim(),
        location_id: parentItem.id,
        description: description.trim(),
        organization_id: parentItem.organization_id
      };
      onAdd(type, parentItem, data);
    } 
    else if (type === "device") {
      if (!name.trim()) {
        setTruncatedMessage("Name is required");
        return;
      }
      if (!deviceData.device_external_id.trim()) {
        setTruncatedMessage("Device External ID is required");
        return;
      }
      if (!parentItem?.id) {
        setTruncatedMessage("Machine ID is required");
        return;
      }
      const data = {
        name: name.trim(),
        device_external_id: deviceData.device_external_id.trim(),
        type: deviceData.type.trim(),
        status: deviceData.status,
        machine_id: parentItem.id,
        organization_id: parentItem.organization_id,
        is_active: true
      };
      onAdd(type, parentItem, data);
    }

    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg shadow-md w-96"
      >
        <h2 className="text-xl font-bold mb-6">
          {`Add ${type.charAt(0).toUpperCase() + type.slice(1)}`}
        </h2>

        {type === "location" && (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Block Name
              </label>
              <input
                type="text"
                value={blockName}
                onChange={(e) => handleInputChange("block_name", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
                placeholder="Enter Block Name"
                maxLength={100}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
                placeholder="Enter Name"
                maxLength={255}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => handleInputChange("description", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Description"
                rows={3}
              />
            </div>
          </>
        )}

        {type === "machine" && (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
                placeholder="Enter Machine Name"
                maxLength={255}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => handleInputChange("description", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Machine Description"
                rows={3}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Location ID
              </label>
              <input
                type="text"
                value={parentItem?.id || ""}
                className="border border-gray-300 rounded w-full px-3 py-2 bg-gray-50"
                disabled
                readOnly
              />
            </div>
          </>
        )}

        {type === "device" && (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
                placeholder="Enter Device Name"
                maxLength={255}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Device External ID
              </label>
              <input
                type="text"
                value={deviceData.device_external_id}
                onChange={(e) => handleInputChange("device_external_id", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
                placeholder="Enter Device External ID"
                maxLength={100}
              />
              <p className="mt-1 text-sm text-gray-500">
                Must be unique within your organization
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type
              </label>
              <input
                type="text"
                value={deviceData.type}
                onChange={(e) => handleInputChange("type", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter Device Type (e.g., sensor)"
                maxLength={50}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                value={deviceData.status}
                onChange={(e) => handleInputChange("status", e.target.value)}
                className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Machine ID
              </label>
              <input
                type="text"
                value={parentItem?.id || ""}
                className="border border-gray-300 rounded w-full px-3 py-2 bg-gray-50"
                disabled
                readOnly
              />
            </div>
          </>
        )}

        {truncatedMessage && (
          <p className="text-red-500 text-sm mb-4">{truncatedMessage}</p>
        )}

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddItemForm;