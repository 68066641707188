import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Clock, Filter, RefreshCw, Calendar } from 'lucide-react';

const TimeSeriesChart = ({ deviceId }) => {
  const [timeRange, setTimeRange] = useState('1h');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [customDateRange, setCustomDateRange] = useState(false);
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');

  const [selectedMetrics, setSelectedMetrics] = useState({
    axial_velocity: true,
    horizontal_velocity: true,
    vertical_velocity: true,
    temperature: false,
    noise: false,
    axial_acceleration: false,
    horizontal_acceleration: false,
    vertical_acceleration: false
  });

  const metrics = [
    { id: 'axial_velocity', name: 'Axial Velocity', color: '#8884d8', unit: 'mm/s' },
    { id: 'horizontal_velocity', name: 'Horizontal Velocity', color: '#82ca9d', unit: 'mm/s' },
    { id: 'vertical_velocity', name: 'Vertical Velocity', color: '#ffc658', unit: 'mm/s' },
    { id: 'temperature', name: 'Temperature', color: '#ff7300', unit: '°C' },
    { id: 'noise', name: 'Noise', color: '#00C4CC', unit: 'dB' },
    { id: 'axial_acceleration', name: 'Axial Acceleration', color: '#a4de6c', unit: 'm/s²' },
    { id: 'horizontal_acceleration', name: 'Horizontal Acceleration', color: '#d0ed57', unit: 'm/s²' },
    { id: 'vertical_acceleration', name: 'Vertical Acceleration', color: '#83a6ed', unit: 'm/s²' }
  ];

  const timeRanges = [
    { value: '5m', label: '5m', interval: '10s' },
    { value: '15m', label: '15m', interval: '30s' },
    { value: '1h', label: '1h', interval: '1m' },
    { value: '6h', label: '6h', interval: '5m' },
    { value: '12h', label: '12h', interval: '10m' },
    { value: '24h', label: '24h', interval: '30m' },
    { value: '7d', label: '7d', interval: '2h' },
    { value: 'custom', label: 'Custom', interval: '5m' }
  ];

  useEffect(() => {
    // Initialize datetime inputs with current time range
    const end = new Date();
    let start;
    
    if (!customDateRange) {
      const range = timeRange.match(/\d+/)?.[0];
      const unit = timeRange.match(/[a-zA-Z]+/)?.[0];
      if (range && unit) {
        start = new Date(end - range * (unit === 'm' ? 60000 : unit === 'h' ? 3600000 : 86400000));
      }
    }
    
    setEndDateTime(end.toISOString().slice(0, 16));
    if (start) {
      setStartDateTime(start.toISOString().slice(0, 16));
    }
  }, [timeRange, customDateRange]);

  const calculateInterval = (startDate, endDate) => {
    const diffHours = (endDate - startDate) / 3600000;
    if (diffHours <= 1) return '1m';
    if (diffHours <= 6) return '5m';
    if (diffHours <= 24) return '15m';
    if (diffHours <= 72) return '30m';
    if (diffHours <= 168) return '1h';
    return '2h';
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token found');

      let start, end, interval;

      if (customDateRange) {
        start = new Date(startDateTime);
        end = new Date(endDateTime);
        interval = calculateInterval(start, end);
      } else {
        const range = timeRange.match(/\d+/)[0];
        const unit = timeRange.match(/[a-zA-Z]+/)[0];
        end = new Date();
        start = new Date(end - range * (unit === 'm' ? 60000 : unit === 'h' ? 3600000 : 86400000));
        interval = timeRanges.find(r => r.value === timeRange)?.interval || '5m';
      }

      const queryParams = new URLSearchParams({
        start: start.toISOString(),
        end: end.toISOString(),
        interval
      });

      const response = await fetch(`/api/dashboard/${deviceId}/timeseriesNew?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-cache'
        }
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (deviceId) {
      fetchData();
      const interval = autoRefresh && !customDateRange ? setInterval(fetchData, 30000) : null;
      return () => interval && clearInterval(interval);
    }
  }, [deviceId, timeRange, customDateRange, startDateTime, endDateTime, autoRefresh]);

  const handleTimeRangeChange = (value) => {
    if (value === 'custom') {
      setCustomDateRange(true);
      setAutoRefresh(false);
    } else {
      setCustomDateRange(false);
      setTimeRange(value);
      setAutoRefresh(true);
    }
  };

  const getTimeFormat = () => {
    if (customDateRange) {
      const start = new Date(startDateTime);
      const end = new Date(endDateTime);
      const diffHours = (end - start) / 3600000;
      
      if (diffHours <= 24) {
        return { format: 'HH:mm', interval: Math.ceil(data.length / 12) };
      } else if (diffHours <= 72) {
        return { format: 'MMM DD, HH:mm', interval: Math.ceil(data.length / 8) };
      } else {
        return { format: 'MMM DD', interval: Math.ceil(data.length / 10) };
      }
    }

    switch (timeRange) {
      case '5m':
      case '15m':
        return { format: 'HH:mm:ss', interval: Math.ceil(data.length / 10) };
      case '1h':
        return { format: 'HH:mm', interval: Math.ceil(data.length / 12) };
      case '6h':
      case '12h':
        return { format: 'HH:mm', interval: Math.ceil(data.length / 8) };
      case '24h':
        return { format: 'MMM DD, HH:mm', interval: Math.ceil(data.length / 12) };
      case '7d':
        return { format: 'MMM DD', interval: Math.ceil(data.length / 14) };
      default:
        return { format: 'HH:mm', interval: Math.ceil(data.length / 10) };
    }
  };

  const formatXAxis = (timestamp) => {
    const date = new Date(timestamp);
    const { format } = getTimeFormat();
    
    return new Intl.DateTimeFormat('en-US', {
      month: format.includes('MMM') ? 'short' : undefined,
      day: format.includes('DD') ? '2-digit' : undefined,
      hour: format.includes('HH') ? '2-digit' : undefined,
      minute: format.includes('mm') ? '2-digit' : undefined,
      second: format.includes('ss') ? '2-digit' : undefined,
      hour12: false
    }).format(date);
  };

  const formatTooltipValue = (value, name) => {
    if (typeof value !== 'number') return value;
    const metric = metrics.find(m => m.name === name);
    return `${value.toFixed(2)} ${metric?.unit || ''}`;
  };

  const formatTooltipLabel = (timestamp) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).format(new Date(timestamp));
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Clock className="h-5 w-5 text-gray-500" />
            <h2 className="text-lg font-medium">Time Series Explorer</h2>
          </div>
          <button
            onClick={() => setAutoRefresh(!autoRefresh)}
            className={`flex items-center gap-2 px-3 py-1.5 rounded text-sm ${
              autoRefresh ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'
            }`}
            disabled={customDateRange}
          >
            <RefreshCw className={`h-4 w-4 ${autoRefresh ? 'animate-spin' : ''}`} />
            {autoRefresh ? 'Auto-refresh ON' : 'Auto-refresh OFF'}
          </button>
        </div>

        {/* Time Range & Filters */}
        <div className="flex flex-col gap-4">
          <div className="flex gap-2">
            {timeRanges.map(({ value, label }) => (
              <button
                key={value}
                onClick={() => handleTimeRangeChange(value)}
                className={`px-3 py-1.5 rounded text-sm ${
                  (value === 'custom' ? customDateRange : timeRange === value)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {label}
              </button>
            ))}
          </div>

          {customDateRange && (
            <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
              <Calendar className="h-5 w-5 text-gray-400" />
              <div className="flex gap-4">
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-600">Start Date & Time</label>
                  <input
                    type="datetime-local"
                    value={startDateTime}
                    onChange={(e) => setStartDateTime(e.target.value)}
                    className="px-3 py-1.5 rounded border border-gray-300"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-600">End Date & Time</label>
                  <input
                    type="datetime-local"
                    value={endDateTime}
                    onChange={(e) => setEndDateTime(e.target.value)}
                    className="px-3 py-1.5 rounded border border-gray-300"
                  />
                </div>
                <button
                  onClick={fetchData}
                  className="self-end px-4 py-1.5 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Metric Toggles */}
        <div className="flex flex-wrap gap-3 p-4 bg-gray-50 rounded-lg">
          <Filter className="h-5 w-5 text-gray-400" />
          {metrics.map((metric) => (
            <label key={metric.id} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={selectedMetrics[metric.id]}
                onChange={() => setSelectedMetrics(prev => ({
                  ...prev,
                  [metric.id]: !prev[metric.id]
                }))}
                className="rounded border-gray-300"
              />
              <span className="text-sm flex items-center gap-1">
                <span style={{ color: metric.color }}>{metric.name}</span>
                <span className="text-gray-400 text-xs">({metric.unit})</span>
              </span>
            </label>
          ))}
        </div>

        {/* Chart */}
        <div className="h-[400px]">
          {loading ? (
            <div className="h-full flex items-center justify-center">
              <span className="text-gray-500">Loading data...</span>
            </div>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 25 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={formatXAxis}
                  interval={getTimeFormat().interval}
                  tick={{ 
                    fontSize: 11,
                    angle: -45,
                    textAnchor: 'end',
                    dy: 10
                  }}
                  height={60}
                />
                <YAxis tick={{ fontSize: 12 }} />
                <Tooltip
                  formatter={formatTooltipValue}
                  labelFormatter={formatTooltipLabel}
                  contentStyle={{
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    border: '1px solid #eee',
                    borderRadius: '4px'
                  }}
                />
                <Legend />
                {metrics.map((metric) => (
                  selectedMetrics[metric.id] && (
                    <Line
                      key={metric.id}
                      type="monotone"
                      dataKey={metric.id}
                      name={metric.name}
                      stroke={metric.color}
                      dot={false}
                      strokeWidth={1.5}
                    />
                  )
                ))}
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeSeriesChart;