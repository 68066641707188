import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const OAuthCallback = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(true);
  
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      const error = params.get('error');
  
      if (error) {
          const errorMessages = {
              'no_code': 'Authentication failed: No code received',
              'token_exchange': 'Authentication failed: Could not exchange token',
              'user_info': 'Authentication failed: Could not fetch user info',
              'invalid_state': 'Authentication failed: Invalid state'
          };
          setError(errorMessages[error] || 'Authentication failed');
          setIsProcessing(false);
          setTimeout(() => navigate('/login'), 3000);
          return;
      }
    
      if (token) {
        try {
          // Store the token
          localStorage.setItem('token', token);
          
          // Clean up URL
          window.history.replaceState({}, document.title, window.location.pathname);
          
          // Verify token by making test requests
          Promise.all([
            fetch('/api/organization', {
              headers: { 
                'Authorization': `Bearer ${token}`,
                'Cache-Control': 'no-cache'
              }
            }),
            fetch('/api/hierarchy', {
              headers: { 
                'Authorization': `Bearer ${token}`,
                'Cache-Control': 'no-cache'
              }
            })
          ])
          .then(([orgResponse, hierarchyResponse]) => {
            if (orgResponse.ok && hierarchyResponse.ok) {
              setIsProcessing(false);
              navigate('/', { replace: true }); // Use replace to prevent going back to callback
            } else {
              throw new Error('Token validation failed');
            }
          })
          .catch(err => {
            console.error('Auth validation error:', err);
            localStorage.removeItem('token');
            setError('Authentication failed: ' + err.message);
            setIsProcessing(false);
            setTimeout(() => navigate('/login', { replace: true }), 3000);
          });
        } catch (err) {
          setError('Invalid token received');
          setIsProcessing(false);
          localStorage.removeItem('token');
          setTimeout(() => navigate('/login', { replace: true }), 3000);
        }
      } else {
        setError('No authentication token received');
        setIsProcessing(false);
        setTimeout(() => navigate('/login', { replace: true }), 3000);
      }
    }, [navigate]);
  
    if (error) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="max-w-md w-full space-y-8 p-8">
            <div className="text-center">
              <h2 className="text-xl font-semibold text-red-600 mb-2">
                Authentication Error
              </h2>
              <p className="text-gray-600">{error}</p>
              <p className="text-sm text-gray-500 mt-2">
                Redirecting to login page...
              </p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-8">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              {isProcessing ? 'Completing Login' : 'Login Successful'}
            </h2>
            <p className="text-gray-600">
              {isProcessing 
                ? 'Please wait while we finish setting up your session...'
                : 'You will be redirected to the dashboard...'}
            </p>
          </div>
        </div>
      </div>
    );
};

export default OAuthCallback;