import React, { useState, useEffect, useContext } from "react";
import "../styles/Header.css"; 
import { LogOutIcon, Bell } from "lucide-react"; 
import { logout } from "./utils/api";
import { DeviceContext } from '../components/contexts/DeviceContext'; 

function Header() {
  const { toggleAlerts } = useContext(DeviceContext);
  const [organization, setOrganization] = useState("");  

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch("/api/organization", {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache'
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch organization name");
        return response.json();
      })
      .then((data) => setOrganization(data.organization))
      .catch((error) => console.error("Error fetching organization:", error));
  }, []);

  return (
    <header className="bg-grey-600 p-4 text-black flex justify-between shadow-md items-center">
      <h1 className="text-2xl font-bold text-blue-600">{organization}</h1>
      <div className="flex items-center space-x-4">
        <button 
          className="flex items-center space-x-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300" 
          onClick={logout}
        >
          <LogOutIcon className="h-5 w-5" />
          <span>Logout</span>
        </button>
      </div>
    </header>
  );
}

export default Header;