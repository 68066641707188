
import React, { createContext, useState } from 'react';

export const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [deviceId, setDeviceId] = useState(null);
  const [showAlerts, setShowAlerts] = useState(false);

  const toggleAlerts = () => {
    setShowAlerts((prev) => !prev);
  };

  return (
    <DeviceContext.Provider value={{ deviceId, setDeviceId, showAlerts, toggleAlerts }}>
      {children}
    </DeviceContext.Provider>
  );
};
