import React from 'react';
import '../styles/Machine.css';
import { Card, Section } from './Cards';
import { FaBell } from 'react-icons/fa'; // Install react-icons if not already installed


const AssetTable = () => {
  const data = [
    {
      assetName: "8T-Boiler-FD Fan Motor",
      status: "High Risk",
      healthScore: 37
    }
  ];

  return (
    <div style={{ marginTop: '20px' }}>
      <header className="header2">Auto Diagnostic and Recommendations</header>

      <table className="table">
        <thead>
          <tr>
            <th>Asset Name</th>
            <th>Status</th>
            <th>Health Score</th>
          </tr>
        </thead>
        <tbody>
          {data.map((asset, index) => (
            <tr key={index}>
              <td>{asset.assetName}</td>
              <td>{asset.status}</td>
              <td>{asset.healthScore}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SubMachine = () => {
  return (
    <div className="App">
      <header className="header2">Dr. Reddy's Laboratories Ltd, CTO - 2</header>
      <div className="headerContainer">
        <h2 className="subheader">Real-Time Device Status</h2>
        {/*<h2 className="subheader alarmHeader">Alarms</h2>*/}
      </div>
      

      {/* Display SubMachine Section first */}
      <Section title="Real time Device Status">
        <Card title="Devices" text="Devices 20" />
        <Card title="Critical" status="CRITICAL" progress={20} />
        <Card title="Warning" status="WARNING" progress={30} />
        <Card title="Operational" status="OPERATIONAL" progress={64} />
        <Card title="Idle" status="IDLE" progress={93} />
        <Card title="Disconnected" status="DISCONNECTED" progress={18} />
        <Card title="Alarms" status="ALARMS" count={18} icon={<FaBell />} />
      </Section>
      

      {/* Display Asset Table after SubMachine Section */}
      <AssetTable />
    </div>
  );
}

export default SubMachine;
