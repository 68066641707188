import React from 'react';
import '../styles/Cards.css';
import CircularProgress from './Circleprogressbar'; // Import Circular Progress Bar



function Card({ text,value, status, progress,children,icon }) {
  return (
    <div className="card">
            <h3>{text}</h3>
            <h3>{value}</h3>
            <p id="sts">
        {status === "ALARMS" ? (
          <span className="alarm-status">
            {status}
            <span className="icon-container">{icon}</span>
          </span>
        ) : (
          status
        )}
      </p>
            {children}
            {progress !== undefined && ( // Render Circular Progress if progress prop is passed
      <div className="progress-container">

        <CircularProgress value={progress} />
        </div>
      )}
    </div>
  );
};
function Section({ title, children }) {
  return (
    <div className="section">
      {/*<h3>{title}</h3>*/}
      <div className="section-content">{children}</div>
    </div>
  );
}

export  {Card,Section};