import React, { useEffect, useState } from "react";

const Organization = () => {
  const [organization, setOrganization] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch("/api/organization", {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch organization name");
        }
        return response.json();
      })
      .then((data) => {
        setOrganization(data.organization);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching organization:", error);
        setError("Failed to load organization data.");
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading && <p className="text-gray-500">Loading organization...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && !error && (
        <h1 className="text-xl font-bold text-sky-700 p-5 m-5">
          Welcome to {organization}
        </h1> 
      )}
    </div>
  );
};

export default Organization;