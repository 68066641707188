  export const fetchWithAuth = async (url, options = {}) => {
    let token = localStorage.getItem('token');
    
    if (!token) {
      window.location.href = '/login';
      throw new Error('No authentication token');
    }
  
    // Add retry mechanism for token validation
    const maxRetries = 2;
    let retryCount = 0;
  
    const makeRequest = async () => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        ...options.headers,
      };
  
      try {
        const response = await fetch(url, {
          ...options,
          headers,
        });
  
        if (response.status === 401 && retryCount < maxRetries) {
          retryCount++;
          // Add a small delay before retry
          await new Promise(resolve => setTimeout(resolve, 1000));
          return makeRequest();
        }
  
        if (response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/login';
          throw new Error('Unauthorized');
        }
  
        return response;
      } catch (error) {
        if (error.message === 'Failed to fetch') {
          throw new Error('Network error - please check your connection');
        }
        throw error;
      }
    };
  
    return makeRequest();
  };
  
  export const logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };
