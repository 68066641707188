
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SideNav from './components/SideNav';
import MachineForm from './components/MachineForm';
import Login from './components/Login';
import Header from "./components/Header"
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import OAuthCallback from './components/OAuthCallback';
import Machine from "./components/Machine";
import Location from "./components/Location"

import { DeviceProvider } from './components/contexts/DeviceContext';


import './styles/layout.css';

// Layout component that includes the SideNav
const Layout = ({ children,userRole }) => (
  <>
  <Header/>
  <div className="flex h-screen bg-gray-100">

    <SideNav userRole={userRole}/>
    <div className="flex-1 overflow-auto">
      {children}
    </div>
  </div>
  </>
);

// Enhanced Protected Route component with token validation
const PrivateRoute = ({ children }) => {
  const [userRole, setUserRole] = useState("");
  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        return;
      }

      try {
        // Validate token format
        const parts = token.split('.');
        if (parts.length !== 3) {
          throw new Error('Invalid token format');
        }

        // Check token expiration
        const payload = JSON.parse(atob(parts[1]));
        if (payload.exp && payload.exp * 1000 < Date.now()) {
          throw new Error('Token expired');
        }

        // Verify token with backend
        const response = await fetch('/api/organization', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache'
          }
        });

        if (!response.ok) {
          throw new Error('Token validation failed');
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
    };

    const fetchUserRole = async() =>{
      const email = localStorage.getItem('email');
    
      try{
        const roleRes = await fetch(`/api/users?email=${email}`); 
       
        if(roleRes.ok){
          const userData = await roleRes.json();
          if(userData.role){
            setUserRole(userData.role)
            console.log("In layout user role is",userData.role)
          }else{
            console.error('Failed to fetch user role', roleRes.statusText)
          }
        }else {
          console.error('Failed to fetch user role:', roleRes.statusText);
        }
      }catch(error){
        console.error("Error fetching user role", error)
      }
    }
  
    verifyToken();
    fetchUserRole();
  }, []); // Empty dependency array means this runs once on mount

  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return <Layout userRole={userRole}>{children}</Layout>;
};

// Auth Check for public routes
const PublicRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  
  if (token) {
    try {
      const parts = token.split('.');
      if (parts.length === 3) {
        const payload = JSON.parse(atob(parts[1]));
        if (payload.exp && payload.exp * 1000 > Date.now()) {
          return <Navigate to="/" replace />;
        }
      }
    } catch (error) {
      localStorage.removeItem('token');
    }
  }

  return children;
};

const App = () => {
  return (
    <DeviceProvider>
    <Router>
      <Routes>
        {/* Public routes with authentication check */}
        <Route 
          path="/login" 
          element={
            <PublicRoute>
              <div className="h-screen">
                <Login />
              </div>
            </PublicRoute>
          }
        />
        
        {/* OAuth Callback route */}
        <Route
          path="/oauth/callback"
          element={
            <div className="h-screen">
              <OAuthCallback />
            </div>
          }
        />

        {/* Protected routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/form"
          element={
            <PrivateRoute>
              <MachineForm />
            </PrivateRoute>
          }
        />
        <Route
          path='/location/:deviceId'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/location"
          element={
            <PrivateRoute>
              <Location />
            </PrivateRoute>
          }
        />
        <Route path="/machine"  element={
            <PrivateRoute>
              <Machine />
            </PrivateRoute>
          }/>
        
        <Route 
            path="/api/machine" 
            element={
              <PrivateRoute>
                <MachineForm />
              </PrivateRoute>
            }
          />

        {/* Catch all route - redirect to home */}
        <Route
          path="*"
          element={
            <Navigate to="/" replace />
          }
        />
      </Routes>
    </Router>
    </DeviceProvider>
  );
};

export default App;