import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronRight, AlertTriangle, Plus, Trash2 } from 'lucide-react';
import AddItemForm from './AddItemForm';
import { fetchWithAuth } from './utils/api';

const NestedItem = ({ item, level = 0, onAdd, onDelete, userRole }) => {
  const [isOpen, setIsOpen] = useState(level === 0);
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = item.to && location.pathname === item.to;

  const onClickL1 = () => {
    navigate("/location");
  };

  const onClickL2 = () => {
    if (item.device_external_id) {
      const deviceId = item.device_external_id;
      navigate(`/location/${deviceId}`);
    } else {
      console.error('Device external ID is missing');
    }
  };

  const handleAdd = (e, type) => {
    e.stopPropagation();
    onAdd(type, item);
  };

  const handleDelete = (e, type) => {
    e.stopPropagation();
    const confirmDelete = window.confirm(`Are you sure you want to delete this ${type}?`);
    if (confirmDelete) {
      onDelete(type, item);
    }
  };

  const getItemColor = () => {
    if (item.isWarning) return 'text-orange-500';
    return level === 0 ? 'text-sky-700' : 'text-gray-700';
  };

  return (
    <li className={`${level > 0 ? 'ml-4' : ''} mb-1`}>
      <div className={`flex items-center justify-between group py-1 hover:bg-gray-50 rounded ${isActive ? 'font-semibold' : ''}`}>
        <div
          className="flex items-center flex-grow cursor-pointer"
          onClick={() => {
            if (level === 0 && item.label !== "Home") {
              onClickL1();
            } else if (level === 2 && item.label !== "Home") {
              onClickL2();
            }
            setIsOpen(!isOpen);
          }}
        >
          {item.children && (
            <span className="mr-1">
              {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            </span>
          )}
          {!item.children && <span className="w-4 mr-1" />}
          {item.isWarning && <AlertTriangle size={16} className="mr-1 text-orange-500" />}
          <span className={getItemColor()}>
            {item.to ? <Link to={item.to}>{item.label}</Link> : item.label}
          </span>
        </div>

        <div className="opacity-0 group-hover:opacity-100 flex items-center">
          {level === 0 && item.label !== "Home" && (
            <>
              {userRole === "editor" && (
                <button
                  onClick={(e) => handleAdd(e, 'machine')}
                  className="hover:text-sky-300 p-1"
                  title="Add Machine"
                >
                  <Plus size={16} />
                </button>
              )}
              {userRole === "editor" && (
                <button
                  onClick={(e) => handleDelete(e, 'location')}
                  className="hover:text-red-400 p-1"
                  title="Delete Location"
                >
                  <Trash2 size={16} />
                </button>
              )}
            </>
          )}
          {level === 1 && (
            <>
              {userRole === 'editor' && (
                <>
                  <button
                    onClick={(e) => handleAdd(e, 'device')}
                    className="hover:text-sky-300 p-1"
                    title="Add Device"
                  >
                    <Plus size={16} />
                  </button>
                  <button
                    onClick={(e) => handleDelete(e, 'machine')}
                    className="hover:text-red-400 p-1"
                    title="Delete Machine"
                  >
                    <Trash2 size={16} />
                  </button>
                </>
              )}
            </>
          )}
          {level === 2 && userRole === 'editor' && (
            <button
              onClick={(e) => handleDelete(e, 'device')}
              className="hover:text-red-400 p-1"
              title="Delete Device"
            >
              <Trash2 size={16} />
            </button>
          )}
        </div>
      </div>
      {isOpen && item.children && (
        <ul className="mt-1">
          {item.children.map((child, index) => (
            <NestedItem
              key={index}
              item={child}
              level={level + 1}
              onAdd={onAdd}
              userRole={userRole}
              onDelete={onDelete}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const SideNav = ({ userRole }) => {
  const [hierarchy, setHierarchy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [formType, setFormType] = useState('');
  const [parentItem, setParentItem] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);
  const navigate = useNavigate();

  const fetchHierarchy = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithAuth('/api/hierarchy');
      if (!response.ok) throw new Error('Failed to fetch hierarchy');
      const data = await response.json();

      console.log("Raw API response:", data);

      if (!Array.isArray(data)) {
        console.error('Invalid data format received:', data);
        throw new Error('Invalid data format received from API');
      }

      const formattedHierarchy = data.map(location => ({
        label: location.block_name,
        id: location.id,
        isActive: location.is_active,
        children: location.machines?.map(machine => ({
          label: machine.name,
          id: machine.id,
          isActive: machine.is_active,
          children: machine.devices?.map(device => ({
            label: device.name,
            id: device.id,
            device_external_id: device.device_external_id,
            to: `/location/${device.device_external_id}`,
            status: device.status,
            isActive: device.is_active,
          })) || []
        })) || []
      }));

      console.log("Formatted hierarchy:", formattedHierarchy);
      setHierarchy(formattedHierarchy);
    } catch (error) {
      console.error('Error fetching hierarchy:', error);
      if (error.message === 'Unauthorized') {
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchHierarchy();
  }, [fetchHierarchy]);

  const handleDelete = async (type, item) => {
    let endpoint = '';
    switch (type) {
      case 'location':
        endpoint = `/api/location/${item.id}`;
        break;
      case 'machine':
        endpoint = `/api/machine/${item.id}`;
        break;
      case 'device':
        endpoint = `/api/device/${item.id}`;
        break;
      default:
        return;
    }

    try {
      const response = await fetchWithAuth(endpoint, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Failed to delete item');
      await fetchHierarchy();
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('Failed to delete item');
    }
  };

  const handleAdd = async (type, parentItem, customItem = null) => {
    if (customItem) {
      let endpoint = '';
      let body = {};

      switch (type) {
        case 'location':
          endpoint = '/api/location';
          body = {
            block_name: customItem.block_name,
            name: customItem.name,
            description: customItem.description
          };
          break;
        case 'machine':
          endpoint = '/api/machine';
          body = {
            name: customItem.name,
            location_id: parentItem.id,
            description: customItem.description
          };
          break;
        case 'device':
          endpoint = '/api/device';
          body = {
            name: customItem.name,
            machine_id: parentItem.id,
            device_external_id: customItem.device_external_id,
            type: customItem.type,
            status: 'active'
          };
          break;
        default:
          console.error('Invalid type:', type);
          return;
      }

      try {
        const response = await fetchWithAuth(endpoint, {
          method: 'POST',
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("API Error:", errorText);
          throw new Error('Failed to add item');
        }

        await fetchHierarchy();
      } catch (error) {
        console.error('Error adding item:', error);
        alert('Failed to add item');
      }
      return;
    }

    setFormType(type);
    setParentItem(parentItem);
    setShowForm(true);
    setSelectedType(type);
    setSelectedParent(parentItem);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setFormType('');
    setParentItem(null);
  };

  if (isLoading) {
    return (
      <div className="bg-white w-64 min-h-screen p-4 border-r flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  return (
    <nav className="bg-white w-64 min-h-screen p-4 border-r">
      <ul className="space-y-2">
        <li className="flex items-center space-x-2">
          <NestedItem item={{ label: "Home", to: "/" }} userRole={userRole} />
          {userRole === 'editor' && (
            <button
              onClick={() => handleAdd('location', null)}
              className="hover:text-sky-300 p-1"
              title="Add Location"
            >
              <Plus size={20} />
            </button>
          )}
        </li>

        {hierarchy.map((item, index) => (
          <NestedItem
            key={index}
            item={item}
            onAdd={handleAdd}
            userRole={userRole}
            onDelete={handleDelete}
          />
        ))}
      </ul>

      {userRole === 'editor' && showForm && (
        <AddItemForm
          type={selectedType}
          parentItem={selectedParent}
          onClose={handleCloseForm}
          onAdd={handleAdd}
        />
      )}
    </nav>
  );
};

export default SideNav;