import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DeviceContext } from "../components/contexts/DeviceContext";
import MetricCard from './MetricCard';
import {ToggleLeft,ToggleRight} from 'lucide-react'
import PrometheusTimeSeriesChart from './TimeSeriesChart';
import "../styles/Speedometer.css"

const Dashboard = () => {
  const { deviceId } = useParams();
  const { showAlerts } = useContext(DeviceContext);
  const { toggleAlerts } = useContext(DeviceContext);

  const [metrics, setMetrics] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [aggregationInterval,setAggregationInterval] = useState('10m')
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const today = new Date();
  const defaultStartDateTime = new Date(today.setHours(0, 0, 0, 0)).toISOString();
  const defaultEndDateTime = new Date().toISOString();


  const [startDateTime, setStartDateTime] = useState(defaultStartDateTime);
  const [endDateTime, setEndDateTime] = useState(defaultEndDateTime);


  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('token');
    
        const dashboardResponse = await fetch(`/api/dashboard/${deviceId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache'
          }
        });
    
        if (!dashboardResponse.ok) throw new Error(`HTTP error! status: ${dashboardResponse.status}`);
        
        const data = await dashboardResponse.json();
        setMetrics(data.metrics);
        setDeviceInfo(data.deviceInfo);
    
        const timeseriesResponse = await fetch(`/api/dashboard/${deviceId}/timeseries`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache'
          }
        });
    
        const timeseriesData = await timeseriesResponse.json();
    
        if (Array.isArray(timeseriesData) && timeseriesData.length > 0) {
          setTimeSeriesData(timeseriesData); 
        } else {
          console.log("Received empty timeseries data:", timeseriesData);
        }
    
        setLoading(false);
      } catch (e) {
        console.error("Error fetching dashboard data:", e);
        setError("Failed to load dashboard data. Please try again later.");
        setLoading(false);
      }
    };

    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 30000);
    return () => clearInterval(interval);
  }, [deviceId, startDateTime, endDateTime, aggregationInterval]);

  // Alerts fetching code
  useEffect(() => {
    const fetchAlerts = async () => {
      if (showAlerts && deviceId) {
        try {   
          const response = await fetch(`/alerts/${deviceId}`);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

          const alertsData = await response.json();
          console.log("Alerts data", alertsData)
          setAlerts(alertsData);
        } catch (e) {
          console.error("Error fetching alerts:", e);
        }
      }
    };

    fetchAlerts();
  }, [showAlerts, deviceId]);

  if (loading) return <div className="p-6">Loading...</div>;
  if (error) return <div className="p-6 text-red-500">{error}</div>;

  return (
    <div className="dashboard-container align-them" style={{overflow:"scroll"}}> 
    <div className='althem'>
      
        <h1 className="text-xl font-bold text-sky-700 alt">Sensor Dashboard</h1>
        <button
          className={`flex items-center space-x-2 px-4 py-2 rounded transition duration-300 alerts-button mt-0 ${
            showAlerts ? "bg-yellow-500 hover:bg-yellow-600" : "bg-gray-300 hover:bg-gray-400"
          }`}
          onClick={toggleAlerts}
        >
          {showAlerts ? (
            <ToggleLeft className="h-8 w-8" /> // Normal bell icon when alerts are on
          ) : (
            <ToggleRight className="h-8 w-8" /> // Slashed bell icon when alerts are off
          )}
          <span>Alerts</span>
        </button>
    
      
    </div>
      {deviceInfo && (
        <div className="device-info-banner mb-6">
          <p className="text-sm text-white">
            Device MAC: {deviceInfo.mac}, Firmware Version: {deviceInfo.firmwareVersion},
            Signal Strength: {deviceInfo.signalStrength}, Health: {deviceInfo.health}
          </p>
        </div>
      )}

      {showAlerts ? (
        <div className="alerts-table-container mb-6">
          <h2 className="text-xl font-bold mb-4">Alerts</h2>
          <table className="w-full table-auto border-collapse border">
            <thead>
              <tr>
                <th className="border px-4 py-2">ID</th>
                <th className="border px-4 py-2">Device ID</th>
                <th className="border px-4 py-2">Timestamp</th>
                <th className="border px-4 py-2">Parameter</th>
                <th className="border px-4 py-2">State</th>
              </tr>
            </thead>
            <tbody>
              {alerts.map(alert => (
                <tr key={alert.id}> {/* Add a unique key here */}
                  <td className="border px-4 py-2">{alert.id}</td>
                  <td className="border px-4 py-2">{alert.device_id}</td>
                  <td className="border px-4 py-2">{new Date(alert.timestamp).toLocaleString()}</td>
                  <td className="border px-4 py-2">{alert.parameter}</td>
                  <td className="border px-4 py-2">{alert.alert_state}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <div className="metrics-grid mb-6 line-metric">
            {metrics.map((metric, index) => (
              <MetricCard 
                key={index}
                title={metric.title}
                value={metric.value}
                max={metric.max} 
              />
            ))}
          </div>

          <PrometheusTimeSeriesChart deviceId={deviceId} />
        </>
      )}
    </div>
  );
};

export default Dashboard; 