import React from 'react';
import '../styles/Machine.css';
import {Card,Section} from './Cards';
import { FaBell } from 'react-icons/fa'; // Install react-icons if not already installed 

const Location=()=> {

  return (
    <div className="App">
      <header className="header">Dr. Reddy's Laboratories Ltd, CTO - 2</header>
      <div className="headerContainer">
        <h2 className="subheader">Real-Time Device Status</h2>
      </div>


      <Section title="Real time Device Status">
      <div className="card-container">
      <Card title="Devices" text="DEVICES" value="20" />
        <Card title="Critical"  status="CRITICAL" progress={20} />
        <Card title="Warning" status="WARNING" progress={30}/>
        <Card title="Idle" status="IDLE" progress={93} />
        <Card title="Disconnected"status="DISCONNECTED" progress={18} />
        <Card title="Alarms" status="ALARMS" count={18} icon={<FaBell />} />
        </div>
      </Section>
      <header className="header">Auto Diagnostics and Recommendations(ADR)</header>
      <Section title="Auto Diagnostics and Recommendations (ADR)">
       <div className="card-container">
       <Card title="Assets"  text="ASSESTS" value="13" /> 
        <Card title="Highest Risk"  status="HIGHEST RISK" progress={36} />
        <Card title="High Risk" status="HIGH RISK" progress={0} />
        <Card title="Medium Risk" status="MEDIUM RISK"progress={20} />
        <Card title="Low Risk" status="LOW RISK" progress={50}/>
        <Card title="No FFT" status="NO FFT" progress={80} />
        </div>
      </Section>


    </div>
  );
}
   
export default Location;

   