import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ value}) => {
  return (
    <div style={{ width: '120px', height: '150px' ,position:'relative' }}>
      <CircularProgressbar
        value={value}
        styles={buildStyles({
          pathColor: value > 66 ? '#00C49F' : value > 33 ? '#FFBB28' : '#FF8042',
          textColor: '#333',
          trailColor: '#d6d6d6',
        })}
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '12px', // Adjust font size as needed
          fontWeight: 'bold',
        }}
      >
        {value}%
      </div>
    </div>
  );
};
    

export default CircularProgressBar;
