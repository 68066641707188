import React, { useState } from 'react';

const MachineForm = () => {
  const [formData, setFormData] = useState({
    type: 'machine', // Can be 'location', 'machine', or 'device'
    locationName: '',
    machineName: '',
    session: '',
    deviceName:'',
    deviceId: '',
  });

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // The handleAdd logic implemented within the component
  const handleAdd = async (type,parentItem,customItem = null) => {
    let name, deviceId;
    const { machineName, deviceName, deviceId: formDeviceId , Name} = formData;

    // Set the name based on type
    if (type === 'location') {
      name = Name;
      if (!name) return alert('Location name is required');
    } else if (type === 'machine') {
      name = machineName;
      if (!name) return alert('Machine name is required');
    } else if (type === 'device') {
      name = formDeviceId; // Assuming you're adding a sensor under submachine
      deviceId = formDeviceId;
      if (!name) return alert('Device name is required');
      if (!deviceId) return alert('Device ID is required for sensor');
    }

    let endpoint = '';
    let body = {};

    switch (type) {
      case 'location':
        endpoint = '/api/location';
        body = { Name: name };
        break;
      case 'machine':
        endpoint = '/api/machine';
        body = { Name: name ,  LocationID: parentItem.id };
        break;
      case 'device':
        endpoint = '/api/device';
        body = { Name: name, DeviceID: deviceId,MachineID: parentItem.id  };
        break;
      default:
        console.error('Unknown type:', type);
        return;
    }

    try {
      console.log(`Sending request to ${endpoint} with body:`, body); // Log the request body
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Failed to add ${type}: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(`${type} added successfully:`, data);
      alert(`${type.charAt(0).toUpperCase() + type.slice(1)} added successfully!`);
      
      // Clear form after successful submission
      setFormData({
        type: 'location',
        locationName: '',
        machineName: '',
        deviceName: '',
        session: '',
        deviceId: '',
      });

    } catch (error) {
      console.error(`Error adding ${type}:`, error);
      alert(`Failed to add ${type}: ${error.message}`);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    handleAdd(formData.type);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md space-y-4"
    >
      <h2 className="text-2xl font-bold mb-4 text-center">Machine Form</h2>

      {/* Select type: machine,device */}
      <div className="space-y-2">
        <label htmlFor="type" className="block text-gray-700 font-semibold">
          Type:
        </label>
        <select
          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
        >
          <option value="location">Location</option>
          <option value="machine">Machine</option>
          <option value="device">Device</option>
        </select>
      </div>

      {/* Location Name */}
      {formData.type === 'location' && (
        <div className="space-y-2">
          <label htmlFor="locationName" className="block text-gray-700 font-semibold">
            Location Name:
          </label>
          <input
            type="text"
            id="locationName"
            name="machineName"
            value={formData.locationName}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
      )}

      {/* Machine Name */}
      {formData.type === 'machine' && (
        <div className="space-y-2">
          <label htmlFor="machineName" className="block text-gray-700 font-semibold">
            Machine Name
          </label>
          <input
            type="text"
            id="machineName"
            name="machineName"
            value={formData.machineName}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
      )}

      {/* Device ID for sensor */}
      {formData.type === 'device' && (
        <div className="space-y-2">
          <label htmlFor="deviceName" className="block text-gray-700 font-semibold">
            Device Name:
          </label>
          <input
            type="text"
            id="deviceName"
            name="deviceName"
            value={formData.deviceName}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
      )}

       {/* Device ID */}
       {formData.type === 'device' && (
        <div className="space-y-2">
          <label htmlFor="deviceId" className="block text-gray-700 font-semibold">
            Device ID:
          </label>
          <input
            type="text"
            id="deviceId"
            name="deviceId"
            value={formData.deviceId}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
      )}

      <button
        type="submit"
        className="w-full py-2 px-4 bg-indigo-500 text-white font-semibold rounded-md shadow-md hover:bg-indigo-600"
      >
        Submit
      </button>
    </form>
  );
};

export default MachineForm;